<template>
  <v-container fluid align-center class="pt-15">
    <CustomCardTooltip ref="tooltipGuide" :tooltips="tooltips" />
    <v-row class="mt-5 mx-5">
      <v-col cols="12">
        <v-card>
          <v-card-title class="align-center justify-center">
            <v-row justify="space-around" no-gutters align="center">
              <v-col cols="12" class="text-left" v-show="$vuetify.breakpoint.smAndUp" :md="$vuetify.breakpoint.smAndUp ? 3 : 4">
                <v-card-title>Filtrar por:</v-card-title>
              </v-col>
              <v-col cols="12" :md="$vuetify.breakpoint.mdAndUp ? 3 : 4">
                <!--                label="Tipo de periodo"-->
                <v-select
                  v-model="record.tipoPeriodo"
                  :items="catalogues.tipos_periodos"
                  item-text="text"
                  item-value="value"
                  prepend-icon="mdi-calendar-check"
                  dense
                  class="mt-5 filtrosPanel"
                  :class="$vuetify.breakpoint.mdAndUp ? 'ml-5 mr-2' : ''"
                  :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                  :disabled="loading"
                  @change="setDateRecord()"
                ></v-select>
              </v-col>
              <v-col cols="12" class="text-center justify-center" :md="$vuetify.breakpoint.mdAndUp ? 3 : 4">
                <template v-if="record.tipoPeriodo === 'mensual'">
                  <v-menu
                    ref="menuRecord"
                    v-model="record.menu"
                    :close-on-content-click="false"
                    :return-value.sync="record.periodo"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!--                      label="Periodo"-->
                      <v-text-field
                        v-model="record.periodo"
                        v-bind="attrs"
                        dense
                        v-on="on"
                        prepend-icon="mdi-calendar"
                        class="mx-2 mt-5"
                        :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                        :disabled="loading"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="record.periodo" type="month" locale="es" style="background-color: #f1f1f1">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="record.menu = false"> Cancelar </v-btn>
                      <v-btn
                        text
                        color="success"
                        @click="
                          $refs.menuRecord.save(record.periodo);
                          changeControl();
                        "
                      >
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </template>
                <template v-else-if="record.tipoPeriodo === 'anual'">
                  <!--                  label="Periodo"-->
                  <v-select
                    v-model="record.periodo"
                    :items="catalogues.years"
                    item-text="text"
                    item-value="value"
                    prepend-icon="mdi-calendar"
                    dense
                    class="mt-3"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-15 mx-2' : ''"
                    :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                    light
                    hide-details
                    :disabled="loading"
                    @change="changeControl()"
                  ></v-select>
                </template>
              </v-col>
              <v-col cols="12" class="text-center justify-center" :md="$vuetify.breakpoint.mdAndUp ? 3 : 4">
                <!--                label="Empresas"-->
                <v-select
                  v-model="record.empresas"
                  multiple
                  :items="catalogues.empresas"
                  item-text="text"
                  item-value="value"
                  prepend-icon="mdi-domain"
                  dense
                  class="mt-6"
                  :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : ''"
                  :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                  :disabled="loading"
                  @change="changeControl()"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-card-text v-show="!loading">
        <v-row no-gutters justify="space-between">
          <div class="card-container">
            <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
              <v-sheet class="text-center tooltipFour" style="position: relative">
                <v-btn icon style="position: absolute; top: 0; right: 0" small class="tooltipOne" @click="$refs.tooltipGuide.start(0)">
                  <v-icon small> mdi-information-outline </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="justify-center font-weight-bold"> Circularidad </v-card-title>
              <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(8 124 23); font-weight: bold" class="pt-10">
                {{ kpis.circularidad.valor }}
              </v-card-subtitle>
            </v-card>
          </div>
          <div class="card-container card-right">
            <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
              <v-sheet class="text-center tooltipFour" style="position: relative">
                <v-btn icon style="position: absolute; top: 0; right: 0" small class="tooltip2" @click="$refs.tooltipGuide.start(1)">
                  <v-icon small> mdi-information-outline </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="justify-center font-weight-bold"> Generación de residuos </v-card-title>
              <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(231 121 36); font-weight: bold" class="pt-10">
                {{ kpis.residuos.valor }}
              </v-card-subtitle>
            </v-card>
          </div>
          <div class="card-container">
            <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
              <v-sheet class="text-center tooltipFour" style="position: relative">
                <v-btn icon style="position: absolute; top: 0; right: 0" small class="tooltip3" @click="$refs.tooltipGuide.start(2)">
                  <v-icon small> mdi-information-outline </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="justify-center font-weight-bold"> Recuperación </v-card-title>
              <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold" class="pt-10">
                {{ kpis.recuperacion.valor }}
              </v-card-subtitle>
            </v-card>
          </div>
          <div class="card-container card-right">
            <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
              <v-sheet class="text-center tooltip4" style="position: relative">
                <v-btn icon style="position: absolute; top: 0; right: 0" small @click="$refs.tooltipGuide.start(3)">
                  <v-icon small> mdi-information-outline </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="justify-center font-weight-bold"> Residuos a relleno </v-card-title>
              <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold" class="pt-10">
                {{ kpis.relleno.valor }}
              </v-card-subtitle>
            </v-card>
          </div>
        </v-row>
      </v-card-text>
      <!--
      <v-col v-show="!errors.kpis" cols="12">
        <v-card min-height="150" min-width="300" class="pa-5 transparent elevation-0" style="display: flex; align-items: center;" >
          <v-row>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-sheet class="text-center tooltipFour" style="position: relative;">
                  <v-btn icon  style="position: absolute; top: 0; right: 0;" small class="tooltipOne" @click="$refs.tooltipGuide.start(0)">
                    <v-icon small>
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </v-sheet>
                <v-card-title class="justify-center font-weight-bold">
                  Circularidad
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(8 124 23); font-weight: bold;" class="pt-10">
                  {{ kpis.circularidad.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-sheet class="text-center tooltipFour" style="position: relative;">
                  <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltip2" @click="$refs.tooltipGuide.start(1)">
                    <v-icon small>
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </v-sheet>
                <v-card-title class="justify-center font-weight-bold">
                  Generación de residuos
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(231 121 36); font-weight: bold;" class="pt-10">
                  {{ kpis.residuos.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-sheet class="text-center tooltipFour" style="position: relative;">
                  <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltip3" @click="$refs.tooltipGuide.start(2)">
                    <v-icon small>
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </v-sheet>
                <v-card-title class="justify-center font-weight-bold">
                  Recuperación
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;" class="pt-10">
                  {{ kpis.recuperacion.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-sheet class="text-center tooltip4" style="position: relative;">
                  <v-btn icon style="position: absolute; top: 0; right: 0;" small @click="$refs.tooltipGuide.start(3)">
                    <v-icon small>
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </v-sheet>
                <v-card-title class="justify-center font-weight-bold">
                  Residuos a relleno
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;" class="pt-10">
                  {{ kpis.relleno.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      -->
      <v-col v-show="loading" cols="12">
        <v-card>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col v-show="!errors.materiales_entrada" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-sheet class="text-center" style="position: relative">
            <v-btn icon style="position: absolute; top: 0; right: 0" small class="tooltip5" @click="$refs.tooltipGuide.start(4)">
              <v-icon size="20"> mdi-information-outline </v-icon>
            </v-btn>
          </v-sheet>
          <v-card-title class="justify-center font-weight-bold">Materiales de entrada </v-card-title>
          <div class="container">
            <Doughnut :chartData="chartData.materiales_entrada.data" :options="chartData.materiales_entrada.options" />
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!errors.recuperacion_material" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-sheet class="text-center tooltip6" style="position: relative">
            <v-btn icon style="position: absolute; top: 0; right: 0" small @click="$refs.tooltipGuide.start(5)">
              <v-icon size="20"> mdi-information-outline </v-icon>
            </v-btn>
          </v-sheet>
          <v-card-title class="justify-center font-weight-bold">Recuperación de material </v-card-title>
          <div class="container">
            <Doughnut :chartData="chartData.recuperacion_material.data" :options="chartData.recuperacion_material.options" />
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5 mx-5">
      <v-col v-show="!errors.historico_materiales" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-sheet class="text-center" style="position: relative">
            <v-btn icon style="position: absolute; top: 0; right: 0" small class="tooltip7" @click="$refs.tooltipGuide.start(6)">
              <v-icon size="20"> mdi-information-outline </v-icon>
            </v-btn>
          </v-sheet>
          <v-card-title class="justify-center font-weight-bold">Histórico de materiales </v-card-title>
          <div class="container">
            <Bar :chartData="chartData.historico_materiales.data" :options="chartData.historico_materiales.options" />
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!errors.medidas_circularidad" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%" style="display: flex !important; flex-direction: column">
          <v-sheet class="text-center tooltip8" style="position: relative">
            <v-btn icon style="position: absolute; top: 0; right: 0" small @click="$refs.tooltipGuide.start(7)">
              <v-icon size="20"> mdi-information-outline </v-icon>
            </v-btn>
          </v-sheet>
          <v-card-title class="justify-center font-weight-bold">Medidas de circularidad </v-card-title>
          <v-card-text style="flex-grow: 1; overflow: auto">
            <v-list-item-group v-model="selectCircularityMeasure" active-class="success--text">
              <template v-for="(item, idx) in medidas_circularidad">
                <v-list-item :key="idx" @click="dialogs.circularityMeasures = true">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.id.replace('MATERIALES ', '').replace('RESIDUOS ', '')"></v-list-item-title>
                      <v-list-item-subtitle class="text--primary" v-text="item.sugerencia"></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.medida"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.ahorro"></v-list-item-action-text>
                      <v-icon v-if="!item.comprometido" color="grey lighten-1"> mdi-check-circle </v-icon>
                      <v-icon v-else color="success"> mdi-check-circle </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="idx <= medidas_circularidad.length - 1" :key="'div-' + idx"></v-divider>
              </template>
            </v-list-item-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-overlay absolute :value="record.loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Generado reporte...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
    <v-dialog v-model="dialogs.circularityMeasures" persistent max-width="390">
      <v-card style="margin-top: 0; background-color: white">
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea actualizar el compromiso con la medida de circularidad?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue-grey" @click="cancelNotification()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="success" @click="updateCircularityMeasure()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import services from '@/utils/services';
import moment from 'moment-timezone';
import Bar from '../Charts/StackedBar.js';
import Doughnut from '../Charts/Donut.js';
import downloadjs from 'downloadjs';
import _ from 'lodash';
import CustomCardTooltip from '@/components/CustomCardTooltip.vue';

export default {
  name: 'dashboardStandard',
  components: { CustomCardTooltip, Bar, Doughnut },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    errors: {
      kpis: true,
      materiales_entrada: true,
      recuperacion_material: true,
      historico_materiales: true,
      medidas_circularidad: true
    },
    msgError: '',
    catalogues: {
      tipos_periodos: [
        { text: 'Mensual', value: 'mensual' },
        { text: 'Anual', value: 'anual' }
      ],
      years: [],
      empresas: []
    },
    record: {
      loading: false,
      tipoPeriodo: 'anual',
      periodo: '',
      menu: false,
      empresas: []
    },
    kpis: {
      circularidad: {
        valor: ''
      },
      residuos: {
        valor: ''
      },
      recuperacion: {
        valor: ''
      },
      relleno: {
        valor: ''
      }
    },
    chartData: {
      materiales_entrada: {
        data: null,
        options: null
      },
      recuperacion_material: {
        data: null,
        options: null
      },
      historico_materiales: {
        data: null,
        options: null
      }
    },
    medidas_circularidad: [],
    selectCircularityMeasure: null,
    dialogs: {
      circularityMeasures: false
    },
    tooltips: [
      {
        selector: '.tooltipOne',
        title: 'Circularidad',
        description:
          '<span>La circularidad se basa en el uso de\n' +
          'materiales secundarios (ej. reciclados o\n' +
          'reusados) para llevar a cabo las\n' +
          'actividades propias de la empresa.\n' +
          'Mientras más alto sea el porcentaje de\n' +
          'circularidad, menor será el impacto\n' +
          'ambiental por extracción de materiales\n' +
          'primarios (vírgenes o no renovables).</span>',
        position: 'right'
      },
      {
        selector: '.tooltip2',
        title: 'Generación de residuos',
        description:
          '<span>Este número refleja la relación\n' +
          'entre la cantidad de residuos\n' +
          'generados y la cantidad de\n' +
          'materiales comprados,\n' +
          'comparando las entradas y\n' +
          'salidas en toneladas.\n' +
          'Para una empresa que está\n' +
          'aspirando a ser “Zero Waste” este\n' +
          'valor idealmente debería de ser\n' +
          'cero.</span>',
        position: 'right'
      },
      {
        selector: '.tooltip3',
        title: 'Recuperación',
        description:
          '<span>El porcentaje de recuperación\n' +
          'hace referencia a la\n' +
          'recolección, gestión o\n' +
          'aprovechamiento de los\n' +
          'residuos sólidos generados por\n' +
          'las actividades propias de la\n' +
          'empresa o externas.</span>',
        position: 'right'
      },
      {
        selector: '.tooltip4',
        title: 'Residuos a relleno',
        description: '<span>Este número indica el\n' + 'porcentaje de los\n' + 'residuos que se\n' + 'envían a relleno\n' + 'sanitario.</span>',
        position: 'bottom'
      },
      {
        selector: '.tooltip5',
        title: 'Materiales de entrada',
        description:
          '<span>Esta gráfica muestra el porcentaje de\n' +
          'material renovable, material reciclado y el\n' +
          'material lineal.\n' +
          'Entre mayor sea el material reciclado y\n' +
          'renovable mejor, ya que estos sustituyen la\n' +
          'materia prima virgen extraíble.\n</span>',
        position: 'right'
      },
      {
        selector: '.tooltip6',
        title: 'Recuperación de material',
        description: '<span>Aquí puedes ver el\n' + 'porcentaje de\n' + 'recuperación de\n' + 'material por tipo de\n' + 'residuo.</span>',
        position: 'bottom'
      },
      {
        selector: '.tooltip7',
        title: 'Histórico de materiales',
        description:
          '<span>Aquí puedes visualizar la\n' +
          'evolución de la circularidad\n' +
          'de tu empresa a lo largo del\n' +
          'tiempo.\n' +
          'Esta herramienta te ayudará\n' +
          'a monitorear cómo cambia\n' +
          'el total de tus residuos con\n' +
          'las diferentes acciones\n' +
          'implementadas.\n</span>',
        position: 'right'
      },
      {
        selector: '.tooltip8',
        title: 'Recuperación de material',
        description:
          '<span>Aquí encontrarás una lista de\n' +
          'medidas de reducción de\n' +
          'emisiones que puedes aplicar en\n' +
          'tu empresa.\n' +
          'La aplicación de estas\n' +
          'estrategias no sólo impulsa una\n' +
          'transición circular sino también\n' +
          'la disminución de emisiones</span>',
        position: 'bottom'
      }
    ]
  }),
  mounted() {
    this.setCatYears();
    this.setCompanies();
    this.setDateRecord();
  },
  computed: {
    company() {
      return this.$store.state.general.company;
    },
    typePeriod() {
      return _.capitalize(this.record.tipoPeriodo);
    },
    report() {
      return this.$store.state.general.report;
    }
  },
  watch: {
    company: function (val) {
      if (val) {
        this.record.empresas = [];
        this.record.empresas.push(val);
      }
      this.changeControl();
    },
    report: function (val) {
      if (val) {
        this.getReport();
      }
    }
  },
  methods: {
    ...mapActions('general', ['setReport']),
    setCatYears() {
      let year = parseInt(moment().format('YYYY'));
      for (let i = 0; i < 5; i++) {
        this.catalogues.years.unshift((year - i).toString());
      }
    },
    setCompanies() {
      const empresas = this.$session.get('companies');
      for (let empresa of empresas) {
        this.catalogues.empresas.push({
          text: empresa.nombre,
          value: empresa.id
        });
      }
      if (this.$store.state.general.company) {
        this.record.empresas.push(this.$store.state.general.company);
      }
    },
    setDateRecord() {
      this.loading = true;
      this.setErrors(true);
      if (this.record.tipoPeriodo === 'mensual') {
        this.record.periodo = moment().format('YYYY-MM');
      } else if (this.record.tipoPeriodo === 'anual') {
        this.record.periodo = moment().format('YYYY');
      }
      setTimeout(() => {
        this.getData();
      }, 200);
    },
    setErrors(val) {
      for (let key in this.errors) {
        this.errors[key] = val;
      }
    },
    changeControl() {
      this.loading = true;
      this.setErrors(true);
      setTimeout(() => {
        this.getData();
      }, 200);
    },
    getDashboard() {
      let params = {
        empresa: this.$store.state.general.company,
        tipo_periodo: this.record.tipoPeriodo,
        periodo: this.record.periodo,
        empresas: this.record.empresas
      };
      this.axios
        .get(services.routes.ec.dashboard, { params })
        .then((response) => {
          this.loading = false;
          this.setErrors(false);
          this.kpis = response.data.data.kpis;
          this.chartData.materiales_entrada = response.data.data.materiales_entrada;
          this.chartData.materiales_entrada.options = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  var total = dataset.data.reduce(function (previousValue, currentValue) {
                    return previousValue + currentValue;
                  });
                  var currentValue = dataset.data[tooltipItem.index];
                  //var percentage = Math.floor(((currentValue / total) * 100)+0.5);
                  return currentValue + '%';
                }
              }
            }
          };
          this.chartData.historico_materiales = response.data.data.historico_materiales;
          console.log(this.chartData.historico_materiales);
          this.chartData.recuperacion_material = response.data.data.recuperacion_material;
          this.medidas_circularidad = response.data.data.medidas_circularidad;
          this.chartData.recuperacion_material.options = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  var total = dataset.data.reduce(function (previousValue, currentValue) {
                    return previousValue + currentValue;
                  });
                  var currentValue = dataset.data[tooltipItem.index];
                  //var percentage = Math.floor(((currentValue / total) * 100)+0.5);
                  return currentValue + '%';
                }
              }
            }
          };
        })
        .catch((error) => {
          this.loading = false;
          this.setErrors(false);
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        });
    },
    getData() {
      if (this.$store.state.general.company) {
        if (this.record.empresas.length) {
          this.getDashboard();
        } else {
          this.loading = false;
          this.error = true;
          this.msgError = 'Debe seleccionar al menos una empresa';
        }
      }
    },
    cancelNotification() {
      this.selectCircularityMeasure = null;
      this.dialogs.circularityMeasures = false;
    },
    updateCircularityMeasure(id) {
      this.loading = true;
      this.setErrors(true);
      setTimeout(() => {
        let medidas = JSON.parse(JSON.stringify(this.medidas_circularidad));
        let compromisos = [];
        medidas[this.selectCircularityMeasure].comprometido = !medidas[this.selectCircularityMeasure].comprometido;
        compromisos = medidas.filter((value) => value.comprometido);
        let data = {
          empresa: this.$store.state.general.company,
          compromisos: compromisos
        };
        this.axios
          .put(services.routes.ec.circularityMeasure, { data })
          .then((response) => {
            this.medidas_circularidad[this.selectCircularityMeasure].comprometido =
              !this.medidas_circularidad[this.selectCircularityMeasure].comprometido;
            this.selectCircularityMeasure = null;
            this.dialogs.circularityMeasures = false;
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
            this.setErrors(false);
          });
      }, 200);
    },
    getReport() {
      this.record.loading = true;
      let params = {
        empresa: this.$store.state.general.company,
        tipo_periodo: this.record.tipoPeriodo,
        periodo: this.record.periodo,
        empresas: this.record.empresas,
        tipo_reporte: 'economia-circular'
      };
      this.axios
        .get(services.routes.ec.report, { params })
        .then((response) => {
          let data = response.data.data;
          let year = this.record.tipoPeriodo === 'anual' ? this.record.periodo : this.record.periodo.split('-')[0];
          downloadjs('data:application/pdf;base64,' + data, 'Reporte_' + year + '.pdf', 'application/pdf');
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.setReport({ val: false });
          this.record.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.text-normal {
  text-transform: none !important;
}
.small {
  max-width: 600px;
}
.container.fill-height {
  justify-content: space-around;
  margin: auto;
}
.v-card {
  margin-top: 10px;
  background-color: rgb(227 227 227 / 28%);
}
#card-cards {
  margin-top: 10px;
}
.card {
  display: flex;
  justify-content: space-around;
}
.v-picker__actions {
  background-color: #f1f1f1;
}
@media screen and (max-width: 1400px) {
  .card-right {
    display: flex;
    justify-content: end;
    align-items: baseline;
  }
  .card-container {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  #card-cards {
    width: 48%;
    margin-bottom: 20px;
  }
  .v-row {
    flex-wrap: wrap;
    justify-content: space-around !important;
  }
}

@media screen and (max-width: 700px) {
  .card-container {
    width: 100%;
    justify-content: center;
    row-gap: 1rem;
    column-gap: 1rem;
  }
  #card-cards {
    width: 100%;
    margin-bottom: 20px;
  }
  .v-row {
    flex-direction: column;
    justify-content: center !important;
  }
}
</style>
